/** Ionic CSS Variables **/
:root .title-ios.title-default,
:root .title-ios.title-large {
  padding-inline: 50px !important;
  padding: 0 50px !important;
}
:root .alert-button-group {
  flex-direction: row !important;
}

.popup-container .popup {
  width: 350px;
}

.number-fixed-label .label-fixed {
  min-width: 70% !important;
  max-width: 70% !important;
}
.number-fixed-label input {
  background-color: lightyellow !important;
}

@media only screen and (min-height: 600px) and (min-width: 768px) {
  .sc-ion-modal-md-h {
    --height: 90%;
    --width: 60%;
  }
}
@media only screen and (min-height: 768px) and (min-width: 768px) {
  .sc-ion-modal-md-h {
    --height: 90%;
    --width: 60%;
  }
}
@media only screen and (min-height: 600px) and (min-width: 768px) {
  .sc-ion-modal-md-h {
    --height: 90%;
    --width: 60%;
  }
}
.lowercase {
  text-transform: lowercase;
}

.textarea-wrapper {
  height: 100%;
}

.span-button {
  padding: 2px;
  background-color: #dd561e;
  vertical-align: -webkit-baseline-middle;
  color: white;
  font-size: small;
  position: relative;
  top: -5px;
  border-radius: 6px;
}

.icon-btn {
  border: grey solid 1px;
  border-radius: 8px;
  background: beige;
}

.color-primary {
  color: #dd561e;
}

.audio-icon {
  position: absolute;
  top: 0;
  right: 0;
}

.tep-badge-selected {
  background-color: rgb(231, 165, 35);
  color: white;
}

.tep-badge-unselected {
  background-color: cornsilk;
  color: black;
}

ion-tab-bar {
  border: solid 1px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  width: 99.5%;
}

.with-wrap {
  white-space: pre-line !important;
}

.version {
  font-size: 10pt;
  color: #383a3e;
}

.sc-ion-modal-md-h.welcome-modal {
  --width: 90% !important;
  --height: 90% !important;
  --border-radius: 32px !important;
}

.tep-behavior-card {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.tep-card-header {
  padding-top: 8px;
  padding-bottom: 8px;
}

.tep-card-title {
  display: inline;
  font-size: 18px;
  font-weight: 100 !important;
}

.tep-card-header-icon {
  float: right;
  top: -5px !important;
  position: relative;
}

.iwi-game-count {
  width: 55px;
  display: inline-block;
  text-align: center;
  height: 23px;
}

.tep-card-content {
  padding-inline-start: 0 !important;
  padding-inline-end: 0 !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.iwi-card-settings {
  padding-inline-start: 0 !important;
  padding-inline-end: 0 !important;
  padding-bottom: 5px !important;
  padding-top: 5px !important;
}

.tep-card-home {
  background: white;
  margin: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  z-index: 1;
}
.tep-card-home ion-card-content {
  width: 90% !important;
}
.tep-card-home .icon-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
}

.padding-unset,
.p-unset {
  padding-left: unset;
  padding-right: unset;
}

.toast-transparent {
  opacity: 0.8;
}

.grid-meal {
  top: 135px;
  position: relative;
}

.grid-no-padding {
  padding: 0 !important;
}

.border-bottom {
  border-bottom: solid 2px #e7a523;
}

.feelings-icon18 {
  font-size: 18pt;
}

.feelings-icon20 {
  font-size: 20pt;
}

.feeling-range ion-range {
  padding-bottom: 0;
  padding-top: 0;
}

.feeling-zero {
  font-size: 10pt;
  position: absolute;
  top: -3px;
}

/*
    --ion-color-tertiary: #f6f8fb;
    --ion-color-tertiary-rgb: 246, 248, 251;
    --ion-color-tertiary-contrast: #000000;
    --ion-color-tertiary-contrast-rgb: 0, 0, 0;
    --ion-color-tertiary-shade: #d8dadd;
    --ion-color-tertiary-tint: #f7f9fb;
*/
.disable-icon {
  cursor: default;
  opacity: 0.4;
  pointer-events: none;
}

.scroll-container {
  overflow: hidden;
}
.scroll-container ::-webkit-scrollbar {
  display: none;
}
.scroll-container .scroll {
  overflow: auto;
}

.button-multirows {
  height: 60px;
  white-space: normal;
}
.button-multirows .button-inner {
  width: 100px !important;
  margin: 0 auto !important;
}

.cell-border-plan {
  padding: 15% 0 !important;
}

.plan-total {
  background-color: rgba(203, 145, 31, 0.2) !important;
}

.plan-total:nth-of-type(odd) ion-col {
  background-color: transparent;
}

.plan-total:nth-of-type(even) ion-col {
  background-color: transparent;
}

.text-inactive-in-tree {
  color: grey;
  font-style: italic;
}

.text-inactive-global {
  color: grey;
  text-decoration: line-through;
}

.div-with-grid {
  height: 200px;
  display: block;
  overflow-y: auto;
}

.cell-logs-back {
  background-color: #ffe9e0 !important;
  border: 0.5vw solid white;
  height: 175px;
  vertical-align: middle;
  display: flow-root;
  justify-content: center;
  align-items: center;
  color: black;
}
.cell-logs-back h3 {
  margin-top: 0;
}
.cell-logs-back .big-icon-50 {
  padding-top: 3px;
}
@media only screen and (min-width: 768px) {
  .cell-logs-back {
    height: 190px;
  }
}

.cell-toolkit-back {
  background-color: #e06735 !important;
  border: 0.5vw solid white;
  height: 27vh;
  vertical-align: middle;
  display: flow-root;
  justify-content: center;
  align-items: center;
  color: white;
}
.cell-toolkit-back h3 {
  margin-top: 6vh;
}

.tep-toolkit-row {
  border-top: 1px solid #e7a523;
}

.tep-toolkit-row-last {
  border-bottom: 1px solid #e7a523;
}

.tep-tool-row {
  border-top: 1px solid #92949c;
}

.tep-tool-row-last {
  border-bottom: 1px solid #92949c;
}

.tool-branch {
  font-size: 10pt;
}

.tool-name {
  margin: 1px 0;
}

.food-types {
  font-size: 13pt;
  padding: 7px;
  border-radius: 15px;
  margin-bottom: 2px;
}
.food-types.food-percent-total {
  line-height: 34px;
  vertical-align: top;
}
.food-types.food-abbr {
  border-radius: 26px;
  min-width: 52px;
  border: solid 2px transparent;
  cursor: pointer;
}
.food-types.food-abbr.inside {
  text-align: center;
}
.food-types.food-abbr.food-abbr-selected {
  border: solid 2px orange;
}

.reminder {
  font-size: 13pt;
  background: transparent;
  color: #dd561e;
  border: #e7a523 2px solid;
  padding: 7px;
  border-radius: 15px;
  margin-bottom: 2px;
}
.reminder.methods {
  border-radius: 26px;
  min-width: 52px;
  cursor: pointer;
}
.reminder.methods.inside {
  text-align: center;
}
.reminder.methods.selected {
  border: solid 2px transparent;
  color: white;
  background: #dd561e;
}

.food-types-new {
  border-radius: 26px;
  min-width: 58px;
  border: solid 2px transparent;
  cursor: pointer;
  color: white;
  background: #dd561e;
  padding: 5px;
}
.food-types-new.inside {
  text-align: center;
}
.food-types-new.food-percent-total {
  line-height: 34px;
  vertical-align: top;
}
.food-types-new.food-abbr-new {
  text-align: center;
  border-radius: 26px;
  min-width: 52px;
  border: solid 2px transparent;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
}
.food-types-new.food-abbr-new.inside {
  text-align: center;
}
.food-types-new.food-abbr-new.food-abbr-selected {
  border: solid 2px orange;
}

.text-area-height50 {
  height: 50px;
}
.text-area-height50 ion-textarea {
  height: 100%;
}
.text-area-height50 ion-textarea textarea {
  height: 100%;
  background-color: lightyellow !important;
}

.text-area-height100 {
  height: 100px;
}
.text-area-height100 ion-textarea {
  height: 100%;
}
.text-area-height100 ion-textarea textarea {
  height: 100%;
  background-color: lightyellow !important;
}

.text-area-height140 {
  height: 140px;
}
.text-area-height140 ion-textarea {
  height: 100%;
}
.text-area-height140 ion-textarea textarea {
  height: 100%;
  background-color: lightyellow !important;
}

.text-area-height250 {
  height: 250px;
}
.text-area-height250 ion-textarea {
  height: 100%;
}
.text-area-height250 ion-textarea textarea {
  height: 100%;
  background-color: lightyellow !important;
}

.white ion-textarea textarea {
  background-color: white !important;
}

.disabled {
  cursor: default;
  opacity: 0.4;
  pointer-events: none;
}

.sc-ion-textarea-md-h {
  --background: floralwhite;
}

.tep-grid-white ion-col,
.tep-grid-white ion-row {
  background-color: white;
}

.border-white ion-col {
  border: white solid 2px;
}

.tep-grid:nth-of-type(odd),
.bg-light-gold {
  background-color: #f6f8fb;
}

.tep-grid:nth-of-type(even),
.bg-light-orange {
  background-color: #fff6ed;
}

.tep-grid:nth-of-type(odd) ion-col,
transparent {
  background-color: transparent;
}

.tep-grid:nth-of-type(even) ion-col {
  background-color: transparent;
}

.small-modal .modal-wrapper {
  height: 200px;
  width: 330px;
  top: 10%;
  position: absolute;
  display: block;
}

.tep-show {
  display: block;
}

.tep-hide {
  display: none;
}

.zero-min-height {
  min-height: 0px !important;
}

.logo {
  display: block;
  margin: 0 auto;
  width: auto;
  height: auto;
  margin-top: 45px;
}

.logo-start {
  display: block;
  margin: 0 auto;
  height: 54px !important;
  width: auto;
}

.logo10 {
  display: block;
  margin: 0 auto;
  width: auto;
  height: auto;
  margin-top: 10px;
  max-width: 300px !important;
}

.goalTypeBtnSelected {
  --background: --ion-color-primary;
}

.big-icon-30 {
  height: 30px;
  width: 30px;
}

.big-icon-50 {
  height: 50px;
  width: 50px;
}

.fullWidth {
  width: 100% !important;
}

.hidden-image,
.hidden {
  width: 1px;
  height: 1px;
  position: absolute;
  top: -100px;
  left: -100px;
}

.logo-center {
  width: 80%;
  padding-top: 20%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 500px) {
  .small-size {
    font-size: 13px !important;
  }
  .big-icon-50 {
    height: 40px;
    width: 40px;
  }
  ion-card-subtitle {
    font-size: 10px !important;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 570px) {
  .logo-center {
    width: 70%;
    padding-top: 10%;
  }
}
@media only screen and (min-width: 768px) {
  .logo-center {
    width: 60%;
    padding-top: 5%;
  }
}
@media only screen and (min-width: 1200px) {
  .logo-center {
    width: 45%;
    padding-top: 5%;
  }
}
@media only screen and (min-width: 1500px) {
  .logo-center {
    width: 30%;
    padding-top: 5%;
  }
}
.grid-center {
  top: 30%;
  position: fixed;
  width: 100%;
}

.grid-home-center {
  width: 100%;
  height: 100%;
  padding-bottom: 70px;
}
.grid-home-center ion-row {
  height: 100%;
  padding-bottom: 70px;
}
.grid-home-center ion-row ion-col {
  padding-bottom: 140px;
}

.grid-top10 {
  top: 10%;
  position: fixed;
  width: 100%;
}

.quotes-text {
  font-size: 16pt;
  font-family: "Aller-italic";
  white-space: normal;
  font-weight: bolder;
}

.content-center {
  position: fixed;
  top: 50%;
  padding: 15px;
  width: 100%;
}

.form-center10 {
  top: 10%;
}

.form-center20 {
  top: 20%;
}

.loading-wrapper.sc-ion-loading-md {
  background: rgba(231, 165, 35, 0.9);
  border-radius: 10px !important;
  font-weight: bold;
}

.iwi-icon {
  background-image: url("../assets/images/iwiin-logo-small.png") !important;
  background-size: 48px 32px;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
  width: 40px;
  height: 28px;
}

/* style rewrite */
.transaction-details h1 {
  font-size: 16pt;
}

.transaction-details h3 {
  font-size: 13pt;
}

.new-message {
  -webkit-animation: new-message 1s infinite;
  /* Safari 4+ */
  -moz-animation: new-message 1s infinite;
  /* Fx 5+ */
  -o-animation: new-message 1s infinite;
  /* Opera 12+ */
  animation: new-message 1s infinite;
  /* IE 10+, Fx 29+ */
}

.unread-message {
  -webkit-animation: unread-message 5s infinite;
  /* Safari 4+ */
  -moz-animation: unread-message 5s infinite;
  /* Fx 5+ */
  -o-animation: unread-message 5s infinite;
  /* Opera 12+ */
  animation: unread-message 5s infinite;
  /* IE 10+, Fx 29+ */
}

.changes-border {
  border: solid #e6b500 3px !important;
}

@-webkit-keyframes new-message {
  0%, 49% {
    background-color: #11c1f3;
  }
  50%, 100% {
    background-color: #387ef5;
  }
}
@-webkit-keyframes unread-message {
  0%, 89% {
    background-color: #11c1f3;
  }
  90%, 100% {
    background-color: #387ef5;
  }
}
.first {
  /*setting alpha = 0.1*/
  background: rgba(0, 151, 19, 0.1);
}

.bar .title {
  left: 0 !important;
  right: 0 !important;
  text-align: center;
}

.bar .title .title-left {
  text-align: center;
}

.button-checked {
  font-size: 19px !important;
}

.button-inner {
  flex-flow: column !important;
}

.auto-resizable-iframe {
  max-width: 420px;
  margin: 0 auto;
}

.auto-resizable-iframe > div {
  position: relative;
  padding-bottom: 75%;
  height: 0;
}

.auto-resizable-iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.errror {
  color: red !important;
  text-align: center;
  margin-top: 5px;
}

.no-padding .button-bar > .button,
.no-padding p {
  padding: 0 !important;
  margin: 0 !important;
}

.popup-buttons .button {
  padding: 0 !important;
  margin: 0 !important;
}

.button-group-no-padding .button {
  padding: 0 !important;
}

.no-padding .item,
.col div.no-padding {
  padding: 6px 0 5px 0px !important;
}

.no-border,
.no-border .item,
.col div.no-border,
.col .item {
  border: none !important;
}

.emily-mobile-header {
  background-size: 250px 30px;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
}

.selected-product {
  border: 2px #387ef5 solid !important;
}

.selected-product-sale {
  border: 2px yellow solid !important;
}

.capitalize {
  text-transform: capitalize;
}

.footer {
  width: 100%;
  text-align: center;
  bottom: 0;
  z-index: 10000;
}

.qr-code-error {
  position: absolute;
  top: 200px;
  background: white;
  padding: 20px;
  font-weight: bold;
  color: red;
}

.inputError {
  background-color: lightcoral;
}

.not-use-filter {
  color: bisque;
}

.print-note {
  height: 400px;
}

.message-body {
  height: 300px;
}

.message-body100 {
  height: 100px;
}

.message-body200 {
  height: 200px;
}

.print-note textarea,
.message-body textarea,
.message-body100 textarea,
.message-body100 textarea,
.message-body200 textarea {
  width: 100% !important;
  height: 90% !important;
  min-height: auto !important;
}

.select-product-radio-input {
  width: 30% !important;
}

.print-note textarea {
  height: 100%;
  min-height: auto;
}

.tep-grid-header {
  padding: 5px;
  background: #4854e0 !important;
}
.grid-rows .row:nth-child(odd) {
  background: bisque;
}

.grid-rows .row:nth-child(even) {
  background: #FFF;
}

.grid-norows .row {
  background: transparent !important;
}

.grid-rows .row.selected {
  background-color: #ffc900 !important;
}

.grid-rows .col90 {
  width: 90px;
}

.grid-rows .col60 {
  width: 60px;
}

.tep-grid .item {
  border-width: 0;
}

.tep-grid .col {
  padding: 0;
}

.tep-grid .row {
  padding: 0;
  min-height: 40px;
}

ion-row.tep-grid {
  min-height: 40px;
  padding: 5px;
}

.grid-scroll-50 {
  overflow-y: scroll;
  height: 50%;
}

.grid-bottom {
  bottom: 0;
  position: absolute;
  width: 95%;
  z-index: -1;
}

.grid-header-fixed {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: white;
}

.grid-footer-fixed {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: white;
}

.nowrap {
  white-space: nowrap;
}

.wrap {
  white-space: normal !important;
}

.green {
  color: green !important;
}

.yellow {
  color: yellow !important;
}

.red {
  color: red !important;
}

.black {
  color: black !important;
}

.blue {
  color: blue !important;
}

.grey {
  color: grey !important;
}

.header-button {
  width: 50px !important;
}

.height200px {
  height: 300px;
}

.height200px textarea {
  height: 90% !important;
}

.height200pxHTML {
  height: 220px;
  margin-top: 90px;
}

.font150 {
  font-size: 15rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.fs-70 {
  font-size: 7rem;
}

.fs-40 {
  font-size: 4rem;
}

.fs-32 {
  font-size: 3.2rem;
}

.fs-24 {
  font-size: 2.4rem;
}

.fs-22 {
  font-size: 2.2rem;
}

.fs-20 {
  font-size: 2rem;
}

.fs-19 {
  font-size: 1.9rem;
}

.fs-18 {
  font-size: 1.8rem;
}

.fs-16 {
  font-size: 1.6rem;
}

.fs-18 {
  font-size: 1.8rem;
}

.fs-15 {
  font-size: 1.5rem;
}

.fs-14 {
  font-size: 1.4rem;
}

.fs-12 {
  font-size: 1.2rem !important;
}

.fs-10 {
  font-size: 1rem;
}

.fs-9 {
  font-size: 0.9rem;
}

.fs-8 {
  font-size: 0.8rem;
}

.shift-left {
  left: -4px;
  position: relative;
}

.p-0 {
  padding: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.padding5,
.p-5 {
  padding: 5px;
}

.padding10,
.p-10 {
  padding: 10px;
}

.padding15,
.p-15 {
  padding: 15px;
}

.padding20,
.p-20 {
  padding: 20px;
}

.padding3515,
.p35-15 {
  padding: 35px 15px;
}

.padding-0-15,
.p-0-15 {
  padding: 0 15px;
}

.padding-top5,
.p-t-5 {
  padding-top: 5px;
}

.padding-top10,
.p-t-10 {
  padding-top: 10px;
}

.padding-top15,
.p-t-15 {
  padding-top: 15px;
}

.padding-left5,
.p-l-5 {
  padding-left: 5px;
}

.padding-left10,
.p-l-10 {
  padding-left: 10px;
}

.padding-left20,
.p-l-20 {
  padding-left: 20px;
}

.padding-right5,
.p-r-5 {
  padding-right: 5px;
}

.padding-right10,
.p-r-10 {
  padding-right: 10px;
}

.padding-right20,
.p-r-20 {
  padding-right: 20px;
}

.padding-bottom0,
.p-b-0 {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.padding-top-bottom5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.padding-top-bottom10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.padding-top-bottom15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.margin5,
.m-5 {
  margin: 5px;
}

.margin10,
.m-10 {
  margin: 10px;
}

.margin20,
.m-20 {
  margin: 20px;
}

.margin-right5,
.m-r-5 {
  margin-right: 5px;
}

.margin-left5,
.m-l-5 {
  margin-left: 5px;
}

.margin-right10,
.m-r-10 {
  margin-right: 10px;
}

.margin-left10,
.m-l-10 {
  margin-left: 10px;
}

.margin-left20,
.m-l-20 {
  margin-left: 20px;
}

.margin-bottom5,
.m-b-5 {
  margin-bottom: 5px !important;
}

.margin-bottom10,
.m-b-10 {
  margin-bottom: 10px !important;
}

.margin-top-bottom5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.margin-top-bottom10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.margin-top-bottom15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.margin-top0,
.m-t-0 {
  margin-top: 0px;
}

.height2 {
  height: 2px;
}

.h-15 {
  height: 15px;
}

.text-underlined {
  text-decoration: underline;
}

.text-italic {
  font-style: italic;
}

.text-link {
  color: #e7a523;
  text-decoration: underline;
  cursor: pointer;
}

.text-bold {
  font-weight: bold !important;
}

.center,
.text-center {
  text-align: center !important;
  margin: auto !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.pointer {
  cursor: pointer;
}

.popup-vertical-buttons .popup-buttons {
  display: block;
}

.popup-vertical-buttons .popup-buttons .button {
  display: block;
  min-width: 100% !important;
}

.popup-container .popup {
  width: 450px;
}

.toast-container {
  background-color: orange;
}

.header-client {
  --background: --ion-color-primary;
}

.profile-tab-btn {
  max-width: 23%;
  min-width: 20%;
  height: 35px;
  white-space: normal;
  font-size: x-small;
  --border-radius: 8px 8px 0 0;
  --background: var(--ion-color-secondary-shade);
}
.profile-tab-btn .button-inner {
  width: 100px !important;
  margin: 0 auto !important;
  line-height: 1.5;
}
.profile-tab-btn.selected {
  --background: var(--ion-color-secondary-tint);
}

.status-care-path-icon {
  border-radius: 20px;
  padding: 2px;
  background: #dd561e;
  border: 2px #dd561e solid;
}

.button-edit {
  color: green !important;
  font-weight: bold !important;
}

.button-remove {
  color: red !important;
  font-weight: bold !important;
}

.iwi-status {
  display: block;
  text-align: right;
  position: relative;
  top: 10px;
  right: -5px;
  z-index: 10;
  height: 0;
}

.toolbar-btn-selected {
  font-weight: bold !important;
  background-color: #fff6ed;
}

.icon-btn-selected {
  background-color: #4854e0 !important;
}

.monospace {
  font-family: monospace;
}